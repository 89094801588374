.infobox span, p, ul, li, h1, h2, h3, h4, h5, h6 {
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif !important;
}

.infobox ul {
    list-style-type: circle;
}

.infobox li {
    margin-left: 2rem;
    color: #333333;
}

.date-picker .ant-picker-ok .ant-btn {
    border-radius: 0;
    background: #49000c;
}

.date-picker .ant-picker-ok .ant-btn:hover {
    border-radius: 0;
    background: #ae0622;
}

.date-picker .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #eee!important;
}