.report span, p, ul, li, h1, h2, h3, h4, h5, h6 {
    font-family: 'Barlow', 'Lucida Grande', 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif !important;
}

.report ul {
    list-style-type: circle;
}

.report li {
    margin-left: 2rem;
    color: #333333;
}
